import {getAsciidocStaticPaths, getAsciidocStaticProps} from "@lib/pages/asciidoc-page";
import {AsciidocArticlePage} from "@components/pages/asciidoc-page";
import {NavigationLinkData} from "@lib/nav/link";

export default AsciidocArticlePage

const links: NavigationLinkData[] = [
    {
        type: 'ExternalLink',
        label: 'Javadocs',
        href: '/javadocs',
        uid: '/javadocs',
    },
    {
        type: 'ExternalLink',
        label: 'REST API',
        href: '/rest-api',
        uid: '/rest-api',
    },
]
const rootSlugs = ['docs']

export const getStaticProps = getAsciidocStaticProps(rootSlugs, 'Docs', links)
export const getStaticPaths = getAsciidocStaticPaths(rootSlugs)